(function() {
	var ja = false;
	var topo = document.querySelector('.topo');
	var view = window;

	watchScrollPosition();

	view.addEventListener('scroll',watchScrollPosition);

	function watchScrollPosition() {
		if(ja) return;

		setTimeout(function() {
			ja=false;
		}, (1000/60));

		if(view.scrollY > 0) {
			topo.classList.add('topo--fx');
		}else {
			topo.classList.remove('topo--fx');
		}
	}
})();
