(function() {
	var link = $('#owlServicos .owl__servico');
	var container = $('#owl__servico__description');

	link.each(function() {
		var self = $(this);
		var url = self.attr('href');

		self.click(function(event) {
			event.preventDefault();

			link.removeClass('active');
			self.addClass('active');

			container.html('<div class="loader" aria-label="Loading...">Loading...</div>');

			if(self.data('template')) {
				render(self.data('template'));
			}else {
				getServico({
					url: url,
					trigger: self
				});
			}

			console.log(self.data('template'));
		});

		if(self.hasClass('active')) {
			self.trigger('click');
		}
	});

	function render(template){
		container.html(template);
	}

	function getServico(config) {

		fetch(config.url)
			.then(req=> req.text())
			.then(html=> {
				container.html(html);
				// salva o template na prop data-templte pra não precisar fazer a requisição novamente
				config.trigger.data('template', html);
			});
		// end fetch
	}

})();
