(function() {
	register();

	function mdForm(campo) {
		var input = campo.find('.md__form__input');

		input.focus(function() {
			campo.addClass('focus');
		});

		input.blur(function() {
			if(input.val() == '') {
				campo.removeClass('focus');
			}
		});

		campo.addClass('md__form--ready');
	}

	function register() {0
		$('.md__form:not(.md__form--ready)').each(function() {
			mdForm($(this));
		});

		requestAnimationFrame(register);
	}
})();
