(function() {
	var observer = new IntersectionObserver(function(entries) {
		for(var i=0;i<entries.length;i++) {
			var entry = entries[i];

			if(entry.isIntersecting) {
				var target = $(entry.target);
				var bg     = target.data('lazy-bg');

				target
					.removeAttr('data-lazy-bg')
					.css({
						'--lazy-bg': `url(${JSON.stringify(bg)})`
					});

				observer.unobserve(entry.target);
			}
		}
	}, {
		rootMargin: '200px'
	});

	$('[data-lazy-bg]').each(function(){
		observer.observe(this)
	});
})();
