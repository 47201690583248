(function() {
	var topo 	= document.querySelector('.topo');
	var ja 		= false;

	if($('.topo').hasClass('topo__inicial')) {


		$('body').scrollspy({
			target: '#mainMenu',
			offset: topo.getBoundingClientRect().height
		});

		verifier();

		$(window).resize(verifier);

		function verifier(){
			if(ja) return false;

			setTimeout(function() {
				ja=true;
			}, (1000/60));

			$('body').data('bs.scrollspy')._config.offset = topo.getBoundingClientRect().height;
		}

		$('.topo__inicial .nav a').each(function() {
			var link = $(this);
			var target = link.attr('href');

			link.click(function(e) {
				e.preventDefault();

				$('body, html').animate({
					scrollTop: $(target).offset().top - (topo.getBoundingClientRect().height - 10)
				});

				$('.menu-backdrop').trigger('click');
			});
		});
	}
})();
