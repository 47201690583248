(function (body) {
	const btn = document.querySelector('.mobile__controls__btn');
	const nav = document.querySelector('#mainMenu');

	btn.addEventListener('click', function (event) {
		event.preventDefault();

		// Adicionando a classe que irá tornar o menu visível
		nav.classList.add('topo__navbar--shown');

		// Criando o fundo preto com transparencia
		let bck = document.createElement('div');

		bck.classList.add('menu-backdrop');

		bck.addEventListener('click', event=> {
			event.preventDefault();
			bck.classList.add('hide');
			nav.classList.remove('topo__navbar--shown');
		});

		bck.addEventListener('transitionend', event=>{
			bck.parentNode.removeChild(bck);
		});

		body.appendChild(bck);
	});

})(document.body);
