(function() {
	const observer = new IntersectionObserver(function (entries) {

		for(var i=0;i<entries.length;i++) {
			var entry = entries[i];

			if(entry.isIntersecting) {
				let animationClass = entry.target.dataset.animate.split(' ');

				for(var c=0;c<animationClass.length;c++) {
					entry.target.classList.add(animationClass[c]);
				}

				entry.target.removeAttribute('data-animate');

				observer.unobserve(entry.target);
			}
		}
	}, {
		treshhold: 0.25
	});
	const animatedEls = document.querySelectorAll('[data-animate]');

	for(var e=0;e<animatedEls.length;e++) {
		observer.observe(animatedEls[e],e);
	}

})();
